import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import querystring from 'query-string';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Jumbotron from '../../components/Jumbotron';
import { SECONDARY } from '../../utils/colors';
import Thumb from '../../assets/svgs/bgcadastro.svg';
import Form from '../../components/SignupForm';
import { signup } from '../../api/signup';
import { registerClick, SIGNUP } from '../../api/gtm';
import useAlert, { ALERT_TYPES } from '../../hooks/useAlert';
import useUtm from '../../hooks/useUtm';

const appUrl = process.env.APP_URL;

const SignupPage = ({ location }) => {
  const utm = useUtm(location.search);
  const { showAlert, dismissAlert, Alert } = useAlert();

  const redirect = ({ token }) => {
    const search =
      Object.keys(utm).length > 0 ? querystring.stringify(utm) : '';
    window.location.href = `${appUrl}confirmar-email?token=${token}&${search}`;
  };

  const onSubmit = async (
    { company_name: name, ...values },
    { setSubmitting, setErrors }
  ) => {
    dismissAlert();
    setSubmitting(true);
    try {
      const result = await signup(
        {
          ...values,
          name,
        },
        utm
      );
      const { data } = result;
      registerClick({ event: SIGNUP });
      redirect(data.signup);
    } catch (e) {
      const errors = [
        { key: 'document', word: 'cnpj' },
        { key: 'email', word: 'email' },
      ];

      const fieldError = errors.find(({ word }) =>
        e.message.toLowerCase().includes(word)
      );

      if (fieldError) {
        setErrors({
          [fieldError.key]: e.message.replace('GraphQL error: ', ''),
        });
      } else {
        showAlert({
          color: ALERT_TYPES.error,
          message: e.message.replace('GraphQL error: ', ''),
        });
      }

      throw e;
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Layout theme="blue" location={location}>
      <SEO
        title="Cadastre-se na Central do Frete"
        description="Cadastre-se na Central eo Frete e faça sua cotação de frete muito mais rápida e descomplicada."
      />
      <Jumbotron
        Image={Thumb}
        background={SECONDARY}
        title="Faça seu cadastro"
        subtitle="E comece a usar agora mesmo."
      />
      <Container className="pt-5 pb-5">
        <Row>
          <Col>
            <Form onSubmit={onSubmit} />
            <Alert />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

SignupPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default SignupPage;
